.autocomplete-dropdown-container {
  z-index: 9999;
  position: absolute;
  width: 94%;
  margin-top: 1%;
  color: #545454;
  border: 1px solid #ddd;
  background-color: white;
}

.setting_accrd_roles {
  max-height: calc(100vh - 353px);
  padding-bottom: 41px;
  overflow: auto;
}

.select_coustom svg {
  position: absolute;
  right: 11px;
  top: 12px;
}

.inputSelectRound option:hover {
  background: #f08438 !important;
  color: white !important;
}

.inputSelectRound option:checked {
  background: #f08438 !important;
  color: white !important;
}

.active-like-follow {
  color: #f08438 !important;
}

.svg_icon.active-like-follow svg {
  stroke: #f08438;
  fill: #f08438;
}

.like-selected {
  fill: #f18438;
  stroke: #f18438;
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
}

.theme-background {
  background: #f08438 !important;
  color: #ffffff;
}

.theme-btn {
  border-color: #f08438 !important;
  background: #f08438 !important;
  color: #ffffff;
}

.theme-btn:focus {
  box-shadow: none !important;
}

.autocomplete-dropdown-container .small {
  color: #545454;
  border-bottom-width: 1px solid #ddd;
  padding: 10px;
  display: block;
}

.map_div {
  margin: 10px;
}

.invite .search .form-group input {
  padding-left: 16px;
  font-style: italic;
  height: 40px;
  background-color: #fff;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.cust-checkbox {
  margin-bottom: -8px;
}

.custom-svg {
  stroke: #737373;
}

.custom-svg:hover {
  stroke: #f08438;
}

.custom-svg.active {
  fill: #f08438;
  stroke: #f08438;
}






.custom-svg-internal {
  stroke: #737373;
}

.custom-svg-internal:hover {
  stroke: #288f6c;
}

.custom-svg-internal.active {
  fill: #288f6c;
  stroke: #288f6c;
}

.smily-icon {
  fill: none;
  stroke: #737373;
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
}

.smily-icon-internal {
  fill: none;
  stroke: #737373;
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
}

.smily-icon.active {
  stroke: #f08438 !important;
}

.smily-icon-internal.active {
  stroke: #288f6c !important;
}

.projectIcon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  letter-spacing: 1px;
  font-size: 16px;
  float: left;
}

.wscroll {
  height: calc(100vh - 191px);
  overflow: hidden;
  overflow-y: auto;
}

.wscroll::-webkit-scrollbar {
  -moz-appearance: none !important;
  width: 0.5em;
  height: 1em;
}

.wscroll::-webkit-scrollbar-button {
  -moz-appearance: none !important;
  background: transparent;
  scrollbar-base-color: #0f6674;
}

.wscroll::-webkit-scrollbar-track-piece {
  -moz-appearance: none !important;
  background: transparent;
}

.wscroll::-webkit-scrollbar-thumb {
  -moz-appearance: none !important;
  background: #f08438;
}

.article-preview {
  position: absolute;
  top: 222px;
  /* border-radius: 45px; */
  transform: translateY(-50%);
  background-color: #fff;
  width: 95%;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* border-radius: 59px; */
  border: 1px solid #ddd;
}

.preview-container {
  min-height: 300px;
  overflow-y: scroll;
  max-height: 300px;
  margin-bottom: 40px;
}

.disable-btn {
  color: rgb(240 132 56 / 50%) !important;
}

.disable-btn:hover {
  color: #fff !important;
}

.disable-btn-internal {
  color: #288f6c !important;
  opacity: 0.5;
}

.disable-btn-internal:hover {
  color: #fff !important;
}

.toggle-project-info {
  transform: rotate(180deg);
}

.social-icon-active {
  color: #f08438 !important;
}

.social-icon-active_internal {
  color: #288f6c !important;
}

.svg_icon.social-icon-active svg {
  stroke: #f08438;
  fill: #f08438;
}

.svg_icon.svg_icon.social-icon-active_internal svg {
  stroke: #288f6c;
  fill: #288f6c;
}


.social-icon-active svg .cls-1 {
  fill: unset;
}

.svg_icon.svg_icon.social-icon-active_internal svg .cls-1 {
  fill: unset;
}

.social-icon-active span {
  color: #f08438 !important;
}

.svg_icon.svg_icon.social-icon-active_internal span {
  color: #288f6c !important;
}


.bouncing-animation {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.close-preview-dialog {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
}

.project-action-btn {
  padding: 0 0.4rem;
}

.middle_bar .active_nav svg {
  stroke: #f08438;
}

.middle_bar .active_nav-internal svg {
  stroke: #288f6c;
}

.role_data {
  min-height: 238px !important;
}

.theme-border {
  border: 1px solid #f08438;
}

.theme-border-internal {
  border: 1px solid #288f6c;
}

.gray-border {
  border: 1px solid #ddd;
}

.no-hover-effect a:hover span,
.no-hover-effect a:hover {
  color: #737373 !important;
}

.no-hover-effect a:hover svg {
  stroke: #e0e0e0 !important;
}

.chat-scroll>div {
  padding-bottom: 35px;
  overflow-x: hidden !important;
}

.chat-scroll-first>div {
  padding-bottom: 190px;
  overflow-x: hidden !important;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.disable-svg {
  stroke: #737373;
}

.active-svg {
  stroke: #f18438;
}

.active-svg-fill {
  fill: #f18438;
}

.wscroll.custom_wscroll {
  height: calc(44vh - 50px);
}

.active-project {
  background: #fce6d7;
}

.handle-drag {
  display: flex !important;
  background: transparent !important;
}

.radius-15 {
  border-radius: 15px;
}

.radius-15>div {
  margin-bottom: -25px !important;
}

.project-notification {
  background: #f18438;
  color: white;
  position: absolute;
  top: 0;
  left: 15px;
  font-size: 10px !important;
}



.user_close_info .svg_icon {
  color: #fff;
}

.export-chat {
  color: #000 !important;
  padding: 5px 10px;
}

.simple-msg {
  word-break: break-word;
}

.highlight {
  background-color: #9accfd !important;
}

.left_users.highlight:after {
  border-right: 8px solid #9accfd !important;
}

.right_users .highlight:after {
  border-left: 8px solid #9accfd !important;
}

.MuiTabs-flexContainer .PrivateTabIndicator-root-9 {
  background-color: #f08438 !important;
}

.trash_icon {
  fill: none;
  stroke: #737373;
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
}

.trash_icon_active {
  fill: none;
  stroke: #f08438;
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
}

.translate-dialog {
  max-height: 200px !important;
  overflow-y: scroll !important;
  margin-bottom: 5px !important;
}

.progress-wrapper .progress {
  height: 0.5rem;
}

.customInputBox{
  outline: none;
  /* display: inline-block;
    width: 100%; */
  /*resize: none;*/
  /* color: #495057;
    background-color: rgb(247, 247, 247);
    font-size: 13px;
    max-height: 85px;
    overflow: hidden;
    overflow-y: auto;
    min-height: 34px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin-bottom: -5px;
    word-break: break-word; */
  /*white-space: break-spaces;*/
}

.boxSHH {
  -webkit-box-shadow: 0 0 1px #000;
  box-shadow: 0 0 1px #000;
  border-radius: 10px;
  max-height: 300px;
  overflow: scroll;
  z-index: 9999 !important; 
  position: fixed;
  bottom: 55px;
  left: 10;
  right: 10;
  background: #fff;
  width:max-content
}

.customInputBoxNew {
  width: 100%;
  color: #495057;
  background-color: rgb(247, 247, 247);
  font-size: 13px;
  /* border: 1px solid #ced4da; */
  border-radius: 0.250rem;
  padding: 0.25rem;
  /* word-break: break-word; */
  resize: none;
  min-height: 34px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.customInputBoxNew textarea {
  border: none;
  outline: none;
}

.customInputBox {
  display: inline-block;
  width: 100%;
  /*resize: none;*/
  color: #495057;
  background-color: rgb(247, 247, 247);
  font-size: 13px;
  /* max-height: 85px; */
  /* overflow: hidden;
    overflow-y: auto; */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin-bottom: -5px;
  word-break: break-word;
  /*white-space: break-spaces;*/
  resize: none;
  overflow: auto;
  min-height: 34px;
  max-height: 80vh;
}

.customInputBoxx {
  display: inline-block;
  width: 100%;
  /*resize: none;*/
  color: #495057;
  background-color: rgb(247, 247, 247);
  font-size: 13px;
  /* max-height: 85px; */
  /* overflow: hidden;
    overflow-y: auto; */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin-bottom: -5px;
  word-break: break-word;
  /*white-space: break-spaces;*/
  resize: none;
  overflow: auto;
  min-height: 34px;
  /* max-height: 100px; */
}

.customInputBox:hover {
  cursor: text;
}

.customInputBox:focus {
  /* color: #495057;
  border-color: #80bdff; */
  outline: none;
  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
}

.customInputBox span {
  font-size: 13px !important;
  background: transparent !important;
  color: #495057 !important;
}

.customInputBox br {
  display: none;
}

/* .customTextareaNew {
  max-height: 34px;
} */

.border-radius-0 {
  border-radius: 0 !important;
}

.groupConversation {
  border-radius: 0 !important;
  margin: 0;
}

.disable-group {
  background-color: #f7f7f7;
  color: #f7f7f7;
}

.text-grey {
  color: grey !important;
}

.read_by_member {
  border-bottom: 1px solid #eee;
}

.read_by_member-time {
  border-top: 1px solid #eee;
}

.read_by_member:last-child {
  border-bottom: 1px solid transparent !important;
}

.upload-multi-group-image {
  max-width: 100%;
  max-height: 100%;
  width: 50%;
  padding: 20px;
  object-fit: contain;
  height: 50%;
}

.upload-group-image {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  transform: translate(-50%, -50%);
}

.customize-floating-dialog {
  bottom: 0;
  transform: none;
  width: 100%;
  top: auto;
}

.group-ct-image {
  overflow: hidden;
  display: flex;
  align-items: center;
}

/*group cchat scroll*/

.project-items {
  position: fixed;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  /*white-space: nowrap;*/
  /*transition: all 0.2s;*/
  /*transform: scale(0.98);*/
  /*will-change: transform;*/
  user-select: none;
  cursor: pointer;
}

.project-items::-webkit-scrollbar {
  display: none;
}

.project-items.active-chat {
  /*background: rgba(255, 255, 255, 0.3);*/
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1);
}

.active-chat {
  display: inline-block;
  min-height: 250px;
  min-width: 400px;
  /*margin: 2em 1em;*/
}

/* .article-img .embed-responsive img {
  object-fit: contain !important;
} */

@media screen and (max-width: 500px) {
  .active-chat {
    min-height: 200px;
    min-width: 200px;
  }
}