.CircularProgressbar-path {
    stroke: red;
  }
  .CircularProgressbar-trail {
    stroke: gray;
  }
  .CircularProgressbar-text {
    fill: yellow;
    font-size: 22px;
  }
  .CircularProgressbar-background {
    fill: green;
  }