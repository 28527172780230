.project_chat_user .groupName {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
}

.admin-view-eye {
    position: absolute;
    right: -9px;
    top: -7px;
}
