.group-info-container {
    /* font-family: Arial, sans-serif; */
    /* padding: 10px; */
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dfdfdf;
}

.edit-mode {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.edit-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    resize: vertical;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.edit-input {
    width: 100%;
    padding: 5px;
    /* margin-bottom: 10px; */
    border-radius: 5px;
    resize: vertical;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.edit-buttons {
    display: flex;
    justify-content: flex-end;
}

.action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.action-button svg {
    margin-right: 5px;
}

.action-button:hover {
    opacity: 0.9;
}

.description-container {
    display: flex;
    align-items: center;
}

.description-text {
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #f08438;
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}

.edit-button:hover {
    opacity: 0.8;
}

.description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show-more {
    cursor: pointer;
    color: #f08438;
    margin-left: 5px;
}

.description-containerx {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding-top: 5px; */
    padding-bottom: 5px;
}

.description-dd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* Allow items to wrap if needed */
}


.edit-buttonx {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #f08438;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}