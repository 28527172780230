.user_chat_content {
  width: 80%;
  display: flex;
  clear: both;
  margin-top: 5px;
  /* padding-bottom: 15px; */
}

/*.user_chat_content:last-child {*/
/*    margin-bottom: 15px;*/
/*}*/

.user_chat_content img {
  /*width: 100%;*/
  /*max-height: 200px;*/
  /*height:auto;*/
  border-radius: 4px;
  margin-right: 0;
  object-fit: cover;
}

.user_chat_content .user_info_chat {
  position: relative;
}

.user_chat_content .user_info_chat:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 8px solid #fff;
  content: "";
  position: absolute;
  left: -8px;
  bottom: 4px;
}

.user_chat_content .user_info_chat span {
  float: left;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  font-family: arial, serif;
  line-height: 18px;
  letter-spacing: 0;
}

.user_chat_content .user_info_chat {
  font-size: 14px;
  line-height: 18px;
  background: #fff;
  border-radius: 5px;
  letter-spacing: 1px;
  color: #000;
  padding: 5px 10px;
  float: left;
}

.user_chat_content.right_users {
  flex-flow: row-reverse;
  float: right;
  text-align: right;
}

.user_chat_content.right_users img {
  margin-left: 0px;
  margin-right: 0;
}

.user_chat_content.right_users .user_info_chat:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #f0fff2;
  border-right: 0;
  content: "";
  position: absolute;
  left: inherit;
  right: -8px;
  bottom: 4px;
}

.user_chat_content.right_users .user_info_chat p {
  float: none;
  text-align: left;
  font-size: 13px;
  font-family: arial, serif;
  letter-spacing: 0;
}

.user_info_chat p {
  font-size: 12px;
  font-family: arial;
  letter-spacing: 0;
}

.user_chat_content.right_users .user_info_chat {
  background: #f0fff2;
}

.user_chat_content .small {
  color: #737373;
  text-align: right;
}

.date_chat {
  margin-top: 10px;
  text-align: center;
}

.date_chat p {
  margin-bottom: 0;
  display: inline-block;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  width: 110px;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}
.no_member_chat {
  /* margin-top: 10px; */
  text-align: center;
  /* border-radius: 10px;
  font-size: 12px; */
  /* background: #dbe0ef; */
  /* box-shadow: 0px 1px 0px rgb(0 0 0 / 15%); */
}

.no_member_chat span {
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  /* width: 330px; */
  font-size: 12px;
  padding: 10px;
  color:black;  
  line-height: 18px;
  background: #dbe0ef;
  box-shadow: 0px 1px 0px rgb(0 0 0 / 15%);
  border-radius: 10px;
  margin-right: 5px;
}

.contact .contact-right a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact .contact-right a span {
  font-weight: 400;
  float: none;
  text-align: left;
  color: #737373;
  letter-spacing: 0;
  margin-right: 10px;
  font-size: 13px;
}

.contact .contact-right a img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.contact .contact-right {
  background: #f0fff2;
  border-radius: 5px;
  position: relative;
}

.contact .contact-right:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #f0fff2;
  border-right: 0;
  content: "";
  position: absolute;
  left: inherit;
  right: -8px;
  bottom: 4px;
}

.contact .contact-left a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact .contact-left a span {
  float: none;
  text-align: left;
  color: #737373;
  letter-spacing: 0;
  margin-right: 10px;
  font-size: 13px;
}

.contact .contact-left a img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.contact .contact-left {
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.contact .contact-left:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 8px solid #fff;
  content: "";
  position: absolute;
  left: -8px;
  bottom: 4px;
}

.radius .form-check {
  padding-left: 0;
}

.radius {
  display: flex;
  align-items: center;
}

.radius .radio_cont h5 {
  font-size: 14px;
  color: #000;
}

.radius .radio_cont small {
  font-size: 12px;
}

.radius .radio_cont {
  line-height: 14px;
  padding-left: 45px;
}

.model_input {
  margin: 0;
  display: block;
}

.send_content {
  margin: 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
}

/* Customize the label (the container) */
.radio_inner {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
}

/* Hide the browser's default radio button */
.radio_inner input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark_inner {
  position: absolute;
  top: -7px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f08438;
}

/* On mouse-over, add a grey background color */
.radio_inner:hover input ~ .checkmark_inner {
  background-color: #f08438;
}

/* When the radio button is checked, add a blue background */
.radio_inner input:checked ~ .checkmark_inner {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark_inner:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio_inner input:checked ~ .checkmark_inner:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio_inner .checkmark_inner:after {
  top: 3px;
  left: 3px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #f08438;
}

.chat_user_info {
  margin-left: 20px;
  border-top: 2px solid #f08438 !important;
}

.chat_user_info-internal {
  /* margin-left: 20px; */
  border-top: 2px solid #288f6c !important;
}

.message_ready_by {
  align-items: center;
  position: absolute;
  height: auto;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background: #fff;
  color: #737373;
}

.contextMenu .toggleMenu {
  display: none;
  position: absolute;
  top: -7px;
  right: -7px;
  width: 35px;
  height: 30px;
  background: rgba(240, 255, 242, 0.8);
  cursor: pointer;
  border: none !important;
}

.contextMenu .toggleMenu .angleDownIcon {
  color: #737373;
  width: 60px;
  position: absolute;
  left: -7px;
  top: 5px;
}

.user_info_chat:hover .toggleMenu,
.contact-right:hover .toggleMenu {
  display: block;
  z-index: 999;
}

.user_info_chat:hover .toggleMenu,
.contact-left:hover .toggleMenu {
  display: block;
  z-index: 999;
}

.contextMenu .dropdown button {
  background-color: rgba(240, 255, 242, 0.8) !important;
  border-color: #f0fff2;
}

.contextMenu.left-context .dropdown button {
  background-color: #f9f9f9 !important;
  border-color: #f0fff2;
}

.contact-right .contextMenu .dropdown button {
  background-color: transparent;
  background: linear-gradient(
    240deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0) 70%
  ) !important;
  border-color: transparent;
}

.contact-right .contextMenu .dropdown a {
  justify-content: flex-start !important;
}

.contact-right .contextMenu .toggleMenu .angleDownIcon {
  color: white !important;
  width: 48px;
}

.contact-left .contextMenu .dropdown button {
  background-color: transparent;
  background: linear-gradient(
    240deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0) 70%
  ) !important;
  border-color: transparent;
  right: -6px;
  top: -5px;
}

.contact-left .contextMenu .dropdown a {
  justify-content: flex-start !important;
}

.contact-left .contextMenu .toggleMenu .angleDownIcon {
  color: white !important;
  width: 48px;
}

.contextMenu .dropdown button:after {
  display: none;
}

.contextMenu .dropdown button:focus {
  outline: none !important;
  box-shadow: none;
}

.contextMenu .btn-primary:not(:disabled):not(.disabled).active:focus,
.contextMenu .btn-primary:not(:disabled):not(.disabled):active:focus,
.contextMenu .show > .btn-primary.dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none;
}

.contextMenu.left-context .toggleMenu .angleDownIcon {
  left: -10px;
}

.contextMenu.left-context .toggleMenu {
  top: -5px;
  right: -10px;
}

.group_chat_user_info .userStatus {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
}

.online {
  background: green;
}

.offline {
  background: dimgrey;
}

@media (min-height: 1000px) {
  .group-conv-wrapper {
    height: calc(50vh - 114px) !important;
  }
}

.dropzone {
  width: 100%;
  height: 100%;
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
