body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

.backButton {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  top: 5px;
  right: 10px;
  border-radius: 50%;
  width: 40px;
  padding: 0px 14px;
}

.theme-color {
  color: #f08438 !important;
}

.main_heading:hover {
  color: #f08438;
}

.main_heading-internal:hover {
  color: #288f6c;
}

.setting-logout-btn {
  cursor: pointer !important;
  text-align: left !important;
  font-weight: 600 !important;
  padding: 0;
  color: #212529 !important;
  width: 100%;
  padding: 0 !important;
  text-decoration: none !important;
}

.text-black {
  color: #000;
}
.text-black:hover {
  color: #000;
}

.chat-image-preview {
  width: 100%;
  max-height: calc(100vh - 60px);
}
