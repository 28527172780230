.react-autosuggest__container {
    position: relative;
    padding-right: 5px;
}

.react-autosuggest__input {
    width: 100%;
    padding: 10px 10px;
    border-radius: 4px;
    font-style: italic;
    background: #f7f7f7;
    border: none;
    color: #737373;
    font-size: 14px;
    height: 38px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 99%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 10px;
    border-bottom: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #fff0e5;
}

.react-autosuggest__suggestions-container--open ul li h6.font-weight-bold{
    font-weight: 500 !important;
    font-size: 16px;
    color: #f08438;
    margin-bottom: 0;
}

.react-autosuggest__suggestions-container--open ul li span{font-size: 14px;color: #545454;}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    /*border: 1px solid #999;*/
    /*border-top-width: 0;*/
    /*list-style: none;*/
    /*margin-top: 0;*/
    /*max-height: 143px;*/
    /*overflow-y: auto;*/
    /*padding-left: 0;*/
    /*width: calc(300px + 1rem);*/
    display: block;
    position: absolute;
    top: 0px;
    width: 99%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 2;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.suggestions li {
    cursor: pointer;
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #fff0e5;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.suggestion-name {
    font-weight: 500 !important;
    font-size: 16px;
    color: #f08438;
    margin-bottom: 0;
}

.suggestion-address {
    font-size: 14px;
    color: #545454;
}
