.maindiv {
    display: flex;
    justify-content: space-between;
}
.subdiv {
   align-items: center;
   text-align: center;
}
.label {
    font-size: 15px;
    font-weight: '500';
    color: #000;
}   