.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
}


.info_window {z-index: 99;padding:15px;border-radius: 5px;min-height: 100px;width: 300px;position: absolute; top: -128px !important;background: white;    left: -160px;}
.info_window h5{font-size: 18px;font-weight: 400;color: #000;margin-bottom: 15px;}
.info_window p{font-size: 14px;margin-bottom: 4px;}
.info_window small{font-size: 13px;color: #767676;line-height: 18px;}
.info_button{display: flex;align-items: center;justify-content: flex-end;}
.info_button a{font-size: 14px;padding: 6px 15px;margin-left: 10px;color: #f08438;border-radius: 4px;}
.info_button a:hover{background-color:#f08438;color:#fff;}
a.cross{color: #000;font-weight: 600;font-size: 14px;position: absolute;top: 10px;right: 10px;}

