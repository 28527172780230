.userStatusNewInfo {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

.online {
    background: #f08438;
}

.offline {
    background: dimgrey;
}